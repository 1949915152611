<template>
  <div>
    <content-box title="Our Sponsors" class="col-lg-9 col-xl-7">
      <div class="container">
        <div class="row justify-content-center">
          <div>
            <img src="/img/sponsors/petrageous.jpg" alt="PETRageous" />
          </div>
        </div>
      </div>
      <div class="container pt-5">
        <div class="row">
          <h3>Gold Level Sponsors</h3>
        </div>
        <div class="row justify-content-center pt-4">
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/stateline.jpg"
                atl="StateLine Lawn Care"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/bmj.jpeg"
                alt="BMJ Engineering"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/fantini.jpg"
                alt="Fantini Bakery"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
        </div>

        <div class="row justify-content-center pt-4">
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/amvets.png"
                atl="AmVets Post 147"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/nicholeSargent.png"
                atl="Nichole Sargent: The Listing Chick with J.Barrett Realty"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/sunoco.jpg"
                alt="Mike's Sunoco 215 Water St, Haverhill"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
        </div>

        <div class="row justify-content-center pt-4">
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/earlyContractors.jpg"
                atl="Early Contractors"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/gcConcreteConstruction.png"
                alt="G&C Concrete Construction"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/magellan.jpg"
                alt="Magellan Aerospace"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container pt-5">
        <div class="row">
          <h3>Silver Level Sponsors</h3>
        </div>

        <div class="row justify-content-center pt-4">
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/climateDesignSystems.png"
                alt="Climate Design Systems"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%"></div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%"></div>
          </div>
        </div>
      </div>

      <div class="container pt-5">
        <div class="row">
          <h3>Bronze Level Sponsors</h3>
        </div>

        <div class="row justify-content-center pt-4">
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%">
              <img
                src="/img/sponsors/bradfordOrthodontics.png"
                alt="Bradford Orthodontics"
                class="d-block mw-100 mh-100 m-auto"
              />
            </div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%"></div>
          </div>
          <div class="col align-content-center" style="height: 200px">
            <div style="width: 75%; height: 100%"></div>
          </div>
        </div>
      </div>
    </content-box>
  </div>
</template>

<script>
import contentBox from '@/components/contentBox'

export default {
  name: 'programs',
  components: {
    contentBox,
  },
  data() {
    return {
      //eventually fetch this from an api call
      sponsors: {
        platinum: {
          sponsors: [
            {
              name: 'Petrageous',
              img: 'petrageous.jpg',
              text: 'Charlie, Gretchan & Brian George',
            },
          ],
        },
        gold: {
          sponsors: [
            {
              name: 'StateLine Lawn Care',
              img: '/img/sponsors/stateline.jpg',
              text: '',
            },
            {
              name: 'BMJ Engineering',
              img: '/img/sponsors/bmj.jpeg',
              text: '',
            },
            {
              name: 'Early Contractors',
              img: '/img/sponsors/earlyContractors.jpg',
              text: '',
            },
            {
              name: 'G&C Concrete Construction',
              img: '/img/sponsors/gcConcreteConstruction.png',
              text: '',
            },
            {
              name: 'Fantini Bakery',
              img: '/img/sponsors/fantini.jpg',
              text: '',
            },
            {
              name: 'AMVETS Post 147',
              img: '/img/sponsors/amvets.png',
              text: 'AMVETS Post 147',
            },
            {
              name: 'The Listing Chick with J.Barrett Realty',
              img: '/img/sponsors/nicholeSargent.png',
              text: '',
            },
            {
              name: 'Magellan Aerospace',
              img: '/img/sponsors/magellan.jpg',
              text: '',
            },
            {
              name: 'Sunoco',
              img: '/img/sponsors/sunoco.jpg',
              text:
                "Mike's Sunoco\n" +
                '215 Water St\n' +
                'Haverhill\n' +
                '978-374-9600',
            },
          ],
        },
        silver: {
          sponsors: [
            {
              name: 'Climate Design Systems',
              img: 'climateDesignSystems.png',
              text: '',
            },
          ],
        },
        bronze: {
          sponsors: [
            {
              name: 'Bradford Orthodontics',
              img: 'bradfordOrthodontics.png',
              text: '',
            },
          ],
        },
      },
    }
  },
  computed: {
    littlesPracticeFrequency() {
      let cardinality = [
        'once',
        'twice',
        'three',
        'four',
        'five',
        'six',
        'seven',
      ]
      let frequency = this.programs.littles.days.length

      let practiceFrequency = cardinality[frequency - 1]

      if (frequency > 2) {
        practiceFrequency += ' times'
      }

      return practiceFrequency
    },
    littlesPracticeDays() {
      let practiceDays = ''

      //use slice here to clone the array, that way the splice below doesn't affect our data set
      let days = this.programs.littles.days.slice()

      if (days.length > 0) {
        if (days.length > 1) {
          let lastDay = days.splice(days.length - 1, 1)
          practiceDays = days.join(', ') + ' & ' + lastDay[0]
        } else {
          practiceDays = days[0]
        }
      }

      return practiceDays
    },
    littlesPracticeTimes() {
      return (
        this.programs.littles.time.start +
        '-' +
        this.programs.littles.time.end +
        'p'
      )
    },

    bigsPracticeFrequency() {
      let cardinality = [
        'once',
        'twice',
        'three',
        'four',
        'five',
        'six',
        'seven',
      ]
      let frequency = this.programs.bigs.days.length

      let practiceFrequency = cardinality[frequency - 1]

      if (frequency > 2) {
        practiceFrequency += ' times'
      }

      return practiceFrequency
    },
    bigsPracticeDays() {
      let practiceDays = ''

      //use slice here to clone the array, that way the splice below doesn't affect our data set
      let days = this.programs.bigs.days.slice()

      if (days.length > 0) {
        if (days.length > 1) {
          let lastDay = days.splice(days.length - 1, 1)
          practiceDays = days.join(', ') + ' & ' + lastDay[0]
        } else {
          practiceDays = days[0]
        }
      }

      return practiceDays
    },
    bigsPracticeTimes() {
      return (
        this.programs.bigs.time.start + '-' + this.programs.bigs.time.end + 'p'
      )
    },
  },
}
</script>

<style scoped></style>
